import React, { useEffect, useState } from 'react';
import classes from './SegundoLogin.module.css';
import { useHistory } from 'react-router-dom';
import { isNative } from '../../utils/generic-functions/genericFunctions';
import segundologincaptcha from '../../assets/images/segundologincaptcha.svg';
import useReCaptchaV3 from '../../hooks/useReCaptchaV3';
import Captcha from './Captcha/Captcha';
import VerificarCuil from './VerificarCuil/VerificarCuil';
import { useAppState } from '../../context/AppStateContext';
import { ERROR_TYPES } from '../../constants/errorTypes';
import { toast, ToastContainer } from 'react-toastify';
import { generarCodigo, obtenerMedios } from '../../services/segundoLoginServices';

const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const SegundoLogin = (props) => {
    const { location } = props
    const { Cuil, Metodo } = location.state || {};
    const history = useHistory();
    const siteKey = SITE_KEY;
    const { executeRecaptcha } = useReCaptchaV3(siteKey);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [state, setState] = useState({
        Metodo: Metodo || 0,
        Token: '',
        Medios: null,
        Cuil: Cuil,
    });
    const [method, setMethod] = useState(state.Metodo);

    const { setIsSuccess, isError, setIsError, isLoading, setIsLoading, } = useAppState();

    useEffect(() => {
        setIsSuccess({ key: 'cuil', value: Cuil });
        window.history.replaceState(null, '', '');
    }, []);

    useEffect(() => {
        setState({ ...state, Metodo: !method ? 0 : 1 });
    }, [method])

    useEffect(() => {
        if (isError.message !== '') {
            notify(isError.message);
            if (!Cuil) history.push('/verificar-cuil');
        }
    }, [isError])

    useEffect(() => {
        setIsLoading(true)
        const ObtenerMedios = async () => {
            try {
                const data = await obtenerMedios(Cuil);
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                setState({ ...state, Medios: data.return });
                setIsError(prev => ({
                    value: false,
                    type: '',
                    message: '',
                }));
            } catch (error) {
                console.log(error);
                setIsError(prev => ({
                    value: true,
                    type: ERROR_TYPES.FAILURE,
                    message: "El CUIL ingresado no se encuentra en la base de datos.",
                }));
            } finally {
                setIsLoading(false);
            }
        };
        if (Cuil !== undefined) ObtenerMedios();

    }, [Cuil])

    useEffect(() => {
        if (state.Medios != null && state.Token !== '')
            history.push('/enviar-codigo', state);
    }, [state])

    const notify = (message) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "dark",
        });
    };

    const verifyCaptcha = async () => {
        setIsLoading(true);
        try {
            if (!window.grecaptcha || !window.grecaptcha.enterprise) {
                throw new Error("reCAPTCHA no está disponible.");
            }

            try {
                const recaptchaToken = await executeRecaptcha('verify');

                if (recaptchaToken) {
                    // console.log("Token generado");
                    setCaptchaVerified(true);
                } else {
                    console.error("No se obtuvo un token válido.");
                }
            } catch (error) {
                console.log(error);
            }

        } catch (error) {
            console.error("Error al verificar el captcha:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const GenerarCodigo = async () => {
            try {
                const data = await generarCodigo(
                    Cuil,
                    !method ? 0 : 1, //0: email, 1: sms
                );
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                setState({ ...state, Token: data.return.token });
                setIsError(prev => ({
                    value: false,
                    type: '',
                    message: '',
                }));
            } catch (error) {
                console.log(error);
                setIsError(prev => ({
                    value: true,
                    type: ERROR_TYPES.FAILURE,
                    message: "Error procesando la solicitud",
                }));
            } finally {
                setIsLoading(false);
            }
        };
        GenerarCodigo();
    };

    const handleMetodos = () => {
        setMethod(!method);
    }

    return (
        <>
            {
                isNative() && Cuil
                    ?
                    (
                        <>
                            <div style={{ width: '100dvw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.container}>
                                    <div className={classes.captchaContainerImg}>
                                        <img src={segundologincaptcha} alt="reCAPTCHA" />
                                    </div>

                                    <div className={classes.textContainer}>
                                        <div>Parece que CiDi no está disponible</div>
                                        <p>Para ingresar a VeDi introduce un código temporal, que te enviaremos a
                                            {
                                                method
                                                    ? <span> {`tu teléfono ${state.Medios?.telefono}`}</span>
                                                    : <span> {`tu correo ${state.Medios?.email}`}</span>
                                            }
                                        </p>
                                    </div>

                                    <div className={classes.captchaContainer}>
                                        <Captcha verifyCaptcha={verifyCaptcha} />
                                    </div>

                                    <div>
                                        <button
                                            style={{ width: '95%' }}
                                            disabled={!captchaVerified || !Cuil || isLoading}
                                            onClick={handleSubmit}
                                        >{isLoading ? 'Verificando...' : 'Enviar Código'}</button>
                                        <button
                                            onClick={() => history.push('/')}
                                        >Cancelar</button>
                                    </div>

                                    <div className={classes.footer}>
                                        <p>¿No te llega el código?</p>
                                        <button disabled={isLoading} onClick={handleMetodos}>Probar otro método de autenticación</button>
                                    </div>

                                </div >
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <VerificarCuil />
                        </>
                    )
            }
            <ToastContainer />
        </>
    )
}

export default SegundoLogin;