import React, { useState } from "react";
import classes from "./Captcha.module.css";
import logoRecaptcha from "../../../assets/icons/recaptcha.svg";

const Captcha = ({ verifyCaptcha }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxClick = () => {
        const newState = !isChecked;
        setIsChecked(newState);
        if (newState) {
            verifyCaptcha();
        }
    };

    return (
        <div className={classes.captchaContainer}>
            <label className={classes.captchaCheckbox}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxClick}
                    disabled={isChecked}
                />
                <span className={classes.customCheckbox}></span>
                <span className={classes.customCheckboxText}>No soy un robot</span>

            </label>
            <img src={logoRecaptcha} alt="logo recaptcha" className={classes.imgLogo} />
        </div>
    );
};

export default Captcha;