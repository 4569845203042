import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './Login.module.css';
import logoCidi from '../../../assets/images/cidi.png';
import logoVedi from '../../../assets/images/VeDilogoMobileLogin.png';
import logoCapital from '../../../assets/images/LogoCapitalMobile.png';
import eyeIcon from '../../../assets/icons/mostrar.svg';
import eyeIconOff from '../../../assets/icons/ocultar.svg';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Login = (props) => {
    const { errorUsuario } = props;
    const formRef = useRef();
    const [formData, setFormData] = useState({
        user: '',
        password: ''
    });
    const [displayValue, setDisplayValue] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [cuilError, setCuilError] = useState(null);

    useEffect(() => {
        window.addEventListener("resize", () => {
            const isOpen = window.innerHeight < 600; // Ajusta el valor según el dispositivo
            setIsKeyboardOpen(isOpen);
        });
    }, [window.innerHeight])

    useEffect(() => {
        if (errorUsuario !== null || errorUsuario !== '') notify();

    }, [errorUsuario])


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "user") {
            const rawValue = value.replace(/\D/g, '');
            const maskedValue = applyCuilMask(value);
            setDisplayValue(maskedValue);
            setFormData({
                ...formData,
                [name]: rawValue
            });
            setCuilError(null);
        } else {
            // setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const notify = () => {
        toast.error(errorUsuario, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "dark",
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (cuilError) {
                return;
            }
            history.push('/procesar-login-cidi', { formData });
        } catch (error) {
            console.error('Error en la solicitud de login:', error);
        }
    };
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const applyCuilMask = (value) => {
        // para remover caracteres no numéricos
        value = value.replace(/\D/g, '');

        // Aplica el formato de cuil
        if (value.length <= 2) {
            return value;
        } else if (value.length <= 10) {
            return `${value.slice(0, 2)}-${value.slice(2)}`;
        } else {
            return `${value.slice(0, 2)}-${value.slice(2, 10)}-${value.slice(10, 11)}`;
        }
    };

    const handleBlur = (e) => {
        if (e.target.name === 'user') {
            validateCuil(formData.user);
        }
    };

    const validateCuil = (cuil) => {
        const cuilRegex = /^\d{2}-\d{8}-\d{1}$/;
        if (!cuilRegex.test(applyCuilMask(cuil))) {
            setCuilError('El CUIL ingresado es inválido');
            return false;
        }
        return true;
    };

    return (
        <div className={classes.loginContainer}>
            <div className={classes.loginContainer2}>
                <form ref={formRef} onSubmit={handleSubmit} className={classes.loginForm}>
                    <div className={classes.headerContainer} >
                        <Header isKeyboardOpen={isKeyboardOpen} />
                    </div>
                    <div className={classes.gridContainer}>
                        <div className={classes.gridItem}>
                            <div className={classes.containerBlueText} style={{ paddingTop: isKeyboardOpen ? 0 : 20 }}>
                                <p className={classes.blueText} style={{ fontWeight: 'bold' }}>Ingresa con tu usuario de </p>
                                <img width="120px" height="40px" src={logoCidi} alt="logo cidi" />
                            </div>
                            <input
                                className={classes.loginInput}
                                name="user"
                                value={displayValue}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                placeholder="CUIL"
                                required
                                inputMode="numeric"
                                maxLength={13}
                            />
                            <p className={classes.errorText} style={{ display: cuilError ? 'block' : 'none', }}>
                                {cuilError}
                            </p>

                            <div className={classes.passwordContainer}>
                                <input
                                    className={classes.loginInputPassword}
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    placeholder="Contraseña"
                                    required
                                />
                                <img
                                    src={showPassword ? eyeIconOff : eyeIcon}
                                    alt="Show Password"
                                    className={classes.eyeIcon}
                                    onClick={toggleShowPassword}
                                />
                            </div>
                            <a href="https://cidi.cba.gov.ar/portal/Cuenta/recover-password" rel='noreferrer' target='_blank' className={classes.blueText}>
                                <p style={{ textAlign: 'center' }}>
                                    ¿Olvidaste tu contraseña?
                                </p>
                            </a>
                        </div>
                        <div className={classes.gridItem}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                {/* <div style={{ padding: isKeyboardOpen ? 0 : 20 }}>{''}</div> */}
                                <button type="submit" className={classes.loginButton}>Entrar</button>
                                <a className={classes.cidiButton} href='https://cidi.cba.gov.ar/portal/Cuenta/Register' rel='noreferrer' target='_blank' >No tengo cuenta Cidi</a>
                            </div>
                        </div>
                    </div>
                </form >
            </div>
            <ToastContainer />
        </div >
    );
};

export default Login;


export const Header = (props) => {
    const { isKeyboardOpen } = props;
    return (
        <>
            <div className={classes.loginContainer2}>
                <div className={classes.wave} style={{}} >
                    <div className={classes.imageContainer}>
                        <img src={logoCapital} alt="logo Córdoba" className={classes.logoCapital} style={{ marginTop: '-2%' }} />
                        <img src={logoVedi} alt="Vedi Córdoba" className={classes.logoVedi} />
                        <div />
                    </div>
                </div>
                {isKeyboardOpen && <div className={classes.otroItem} />}
            </div>
        </>
    )
}
