import React from "react";
import { Router, Switch, Route } from "react-router-dom";

import "./App.css";
import LandingPage from "../LandingPage/LandingPage";
import ProcesarLoginCidi from "../ProcesarLoginCIdi/ProcesarLoginCidi";

import { createBrowserHistory } from "history";

import EnviarCodigo from '../SegundoLogin/EnviarCodigo/EnviarCodigo';
import SegundoLogin from '../SegundoLogin/SegundoLogin';
import Login from '../UI/Login/Login';
import VerificarCuil from '../SegundoLogin/VerificarCuil/VerificarCuil';
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions';
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

function App() {
  return (
    <Router history={history} basename="/VeDiLandingPage">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/login-usuario" render={({ location }) => <Login {...location.state} />} />
        <Route path="/verificar-cuil" component={VerificarCuil} />
        <Route path="/segundo-login" component={SegundoLogin} />
        <Route path="/enviar-codigo" component={EnviarCodigo} />
        <Route path="/terminos-y-condiciones" component={TermsAndConditions} />
        <Route
          path="/procesar-login-cidi"
          component={({ location }) => <ProcesarLoginCidi location={location} />}
        />
      </Switch>
    </Router>
  );
}

export default App;
