import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import vediAPI, { getTokenPublico } from '../../utils/vedi-api/vediAPI';
import Loading from '../UI/Loading/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ProcesarLoginCidi = (props) => {
    const { location } = props
    const { formData } = location.state || {};
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);

    useEffect(() => {
        const loginCidiMobile = (formData) => vediAPI.get('/v2/Usuario/LoginCiDiMobile', {
            headers: {
                '--Username': formData.user,
                '--Password': formData.password
            }
        }).then(({ data }) => {
            if (!data.ok) {
                throw new Error(data.error || 'Error procesando la solicitud')
            }
            setErrorUsuario('');
            setError(false);
            const { token, refreshToken, segundoFactor } = data.return;
            document.cookie = `tokenVeDi=${token}; path=/;`;
            document.cookie = `refreshTokenVeDi=${refreshToken}; path=/;`;
            document.cookie = `codigoCidi=${cidi}; path=/`;
            document.cookie = `dobleFactor=${segundoFactor}; path=/`;
            setLoading(false);
            window.open(process.env.REACT_APP_URL_PORTAL, '_self');
        }
        ).catch(error => {
            if (error.response
                && (
                    error.response.status === 400
                    || error.response.status === 404
                    || error.response.status >= 500
                )) {
                setError(true);
            } else {
                setErrorUsuario(error.message);
            }
            setLoading(false);
        });

        const { cidi } = queryString.parse(location.search)

        const loginCIDI = (_token) => vediAPI.get('/v1/Usuario/LoginCiDi', {
            headers: {
                '--token': _token,
                '--Codigo': cidi
            }
        })
            .then(({ data }) => {
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                // const idAplicacion = localStorage.getItem('idAplicacion');
                const { token, refreshToken, segundoFactor } = data.return;
                // if (idAplicacion) {
                //     redirectToApp(idAplicacion, token)
                // } else {
                document.cookie = `tokenVeDi=${token}; path=/`;
                document.cookie = `refreshTokenVeDi=${refreshToken}; path=/`;
                document.cookie = `codigoCidi=${cidi}; path=/`;
                document.cookie = `dobleFactor=${segundoFactor}; path=/`;
                setLoading(false)
                window.open(process.env.REACT_APP_URL_PORTAL, '_self')
                // }
            }).catch(error => {
                if (error.response
                    && (
                        error.response.status === 400
                        || error.response.status >= 500
                    )) {
                    setError(true);
                }
                setLoading(false);
            });

        // return () => {
        //     if (localStorage.getItem('idAplicacion')) {
        //         localStorage.removeItem('idAplicacion');
        //     }
        // }

        if (formData) {
            setLoading(true)
            loginCidiMobile(formData);
            return;
        }

        getTokenPublico()
            .then(token => loginCIDI(token))
            .catch(error => {
                console.log('error en inicio de sesion: ', error);
                setLoading(false);
                setError(true);
            });

    }, [location.search, location.state])

    // const redirectToApp = (idAplicacion, token) => {
    // localStorage.removeItem('idAplicacion');
    // getRepresentadosPorApp(idAplicacion, token)
    //     .then(app => {
    //         if (app) {
    //             window.open(app.urlBase, '_self');
    //         }
    //     })
    //     window.open(process.env.REACT_APP_URL_PORTAL, '_self')
    // }

    useEffect(() => {
        if (error) {
            formData
                ?
                history.push('/segundo-login', { Cuil: formData.user })
                :
                history.push('/verificar-cuil');
        } else if (errorUsuario) {
            formData
                &&
                history.push('/login-usuario', { errorUsuario })
        }
    }, [error, errorUsuario]);

    // if (loading) {
    return <Loading show={loading} />
    // }

}

export default ProcesarLoginCidi