import React from 'react';
import classes from './TermsAndConditions.module.css';
import VeDilogo from '../../assets/images/VeDilogo.png';
import Footer from '../LandingPage/Footer/Footer';

export const TermsAndConditions = () => {
  const handleGoToHome = () => {
    window.location.href = '/VeDiLandingPage';
  };
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <header className={classes.header}>
          <img className={classes.logo} src={VeDilogo} alt="vedi-logo" onClick={handleGoToHome} />
          <h1 className={classes.title}>Terminos y Condiciones</h1>
        </header>
      </div>
      <div className={classes.container}>
        <h2 className={classes.subtitle}>Plataforma Vecino Digital</h2>
        <p>
          La Municipalidad de Córdoba ha desarrollado la plataforma “Vecino Digital”, a los fines de
          centralizar y facilitar a los vecinos de la ciudad el acceso a los servicios digitales que
          brinda la Municipalidad de Córdoba, bajo los siguientes términos y condiciones:
        </p>

        <h3>1. Descripción de la Plataforma</h3>
        <p>
          La presente aplicación denominada “Vecino Digital” está a disposición de los vecinos de la
          ciudad de Córdoba que necesiten gestionar algún servicio o trámite web con la
          Municipalidad de Córdoba, como así también visualizar las notificaciones electrónicas
          recibidas por el ciudadano.
        </p>
        <p>
          El usuario manifiesta conocer que la información suministrada para la creación de su
          perfil/cuenta reviste el carácter de declaración jurada.
        </p>

        <h3>2. Requisitos para el Uso de la Plataforma</h3>
        <p>
          Para la utilización de la Plataforma los usuarios deberán tener registrada su identidad
          digital en la Plataforma de Servicios Ciudadano Digital de la Provincia de Córdoba.
        </p>
        <p>
          El usuario declara y garantiza que todos los datos personales suministrados en el proceso
          de registro de Ciudadano Digital son verdaderos, completos y se encuentran actualizados.
          Será responsabilidad del usuario mantener actualizada su información personal.
        </p>
        <p>
          Si el usuario advirtiera que sus datos de usuario perdieron su condición de confidenciales
          y/o están siendo utilizados por terceros, deberá informar de manera inmediata a la
          Municipalidad mediante el envío de un correo electrónico a: aplicaciones@cordoba.gov.ar.
        </p>
        <p>
          La Municipalidad utilizará la información suministrada por el vecino en su registro de
          Ciudadano Digital con el objeto de permitir al usuario utilizar la plataforma Vecino
          Digital, como así también efectuar comunicaciones por la Municipalidad de Córdoba al
          número de teléfono y e-mail provisto por el usuario.
        </p>
        <p>
          Para poder utilizar la plataforma, sus servicios web y el envío de información, se
          requiere contar con internet por parte del usuario.
        </p>
        <p>
          La Municipalidad podrá rechazar cualquier solicitud de registro o impedir, en cualquier
          momento, que un usuario ingrese a la aplicación siempre que tal ingreso pueda poner en
          riesgo la seguridad de la plataforma y/o que el usuario haya violado los Términos y
          Condiciones.
        </p>

        <h3>3. Información Contenida en la Plataforma</h3>
        <p>
          El contenido y/o uso de la aplicación está orientado exclusivamente a brindar acceso a las
          aplicaciones y servicios web de la Municipalidad de Córdoba; y poner a disposición del
          vecino las notificaciones electrónicas que curse a través de la plataforma Ciudadano
          Digital correspondientes a la Municipalidad de Córdoba.
        </p>

        <h3>4. Utilización de la Aplicación</h3>
        <p>
          Los usuarios deberán utilizar la aplicación de conformidad con las disposiciones
          establecidas en estos términos y condiciones.
        </p>
        <p>
          Queda prohibido su utilización con fines comerciales. Queda terminante prohibido: (i)
          copiar, modificar, adaptar, traducir, realizar ingeniería inversa, descompilar o
          desensamblar cualquier parte del contenido y/o de la aplicación; (ii) hacer uso del
          contenido en otro sitio web o entorno informático para cualquier propósito sin la
          autorización previa y por escrito de la Municipalidad; (iii) reproducir y/o comunicar por
          cualquier medio el contenido, dado que el acceso se otorga en forma personal y para uso
          exclusivo del usuario; (iv) interferir o interrumpir el funcionamiento de la aplicación;
          (v) vender, licenciar o explotar el contenido y/o cualquier tipo de acceso y/o uso de la
          aplicación; (vi) utilizar la aplicación con fines ilícitos o inmorales; e (vii) infringir
          de cualquier modo los presentes Términos y Condiciones.
        </p>

        <h3>5. Protección de Datos Personales</h3>
        <p>
          El usuario presta su consentimiento expreso para que la Aplicación recolecte y procese
          información personal del usuario, con fines exclusivos para la gestión de trámites,
          servicios y comunicaciones de la Municipalidad, fuera de lo cual los datos personales
          aportados por los usuarios quedarán sujetos a las normas de reserva y protección
          establecidas en la Ley N° 25.326 y normativa complementaria.
        </p>
        <p>
          La plataforma es administrada por la Municipalidad de Córdoba, con domicilio en Marcelo T.
          de Alvear N° 120, de la ciudad de Córdoba, ante la cual el usuario podrá ejercer sus
          derechos de acceso, rectificación y supresión de sus datos personales enviando un correo
          electrónico a: aplicaciones@cordoba.gov.ar.
        </p>

        <h3>6. Responsabilidad</h3>
        <p>
          La Municipalidad de Córdoba no será responsable por ningún mal funcionamiento,
          imposibilidad de acceso o malas condiciones de uso de la plataforma, cuando fuera debido
          al uso de equipos inadecuados, interrupciones relacionadas con proveedores de servicio de
          internet, la saturación de la red de internet y/o por cualquier otra causa que dependa
          directa o indirectamente de terceros y/o que no sea imputable a la Municipalidad.
        </p>

        <h3>7. Propiedad Intelectual de la Plataforma</h3>
        <p>
          La plataforma y el contenido de la misma son de titularidad exclusiva de la Municipalidad
          de Córdoba.
        </p>
        <p>
          La Municipalidad de Córdoba se reserva todos los derechos sobre la plataforma, no cede ni
          transfiere a favor del usuario ningún derecho sobre su propiedad intelectual. Los derechos
          de propiedad intelectual respecto de los criterios de selección y/o disposición del
          contenido de la aplicación corresponden exclusivamente a la Municipalidad de Córdoba,
          quedando estrictamente prohibido al usuario utilizar los contenidos, las categorías y/o
          cualquier información de la aplicación con otra finalidad distinta a la indicada en los
          presentes términos y condiciones.
        </p>

        <h3>8. Operatividad de la Plataforma</h3>
        <p>
          La Municipalidad de Córdoba no garantiza el pleno acceso y operatividad de la plataforma
          en forma ininterrumpida.
        </p>
        <p>
          La Municipalidad de Córdoba podrá suspender el acceso a la plataforma y/o a determinado
          contenido por motivos de mantenimiento o de seguridad en cualquier momento, así como
          modificar las prestaciones de la misma.
        </p>

        <h3>9. Notificaciones y Comunicaciones</h3>
        <p>
          Las notificaciones cursadas mediante la Plataforma Ciudadano Digital correspondiente a la
          Municipalidad de Córdoba se considerarán eficaces y plenamente válidas. La Plataforma
          Vecino Digital expondrá al ciudadano las notificaciones que se cursen por ese medio.
        </p>
        <p>
          Asimismo, se considerarán eficaces las comunicaciones que consistan en avisos y mensajes
          insertos en la plataforma que tengan por finalidad informar al usuario sobre determinada
          circunstancia, como por ejemplo un cambio en estos términos o condiciones o en la política
          de privacidad y/o sobre cualquier otra situación de sea de interés para los vecinos.
        </p>

        <h3>10. Legislación Aplicable y Jurisdicción</h3>
        <p>
          A todos los efectos legales en relación a la aplicación, será aplicable la legislación
          vigente en la República Argentina.
        </p>
        <p>
          Cualquier controversia que se suscite entre las partes con relación a estos términos y
          condiciones, su existencia, validez, calificación, interpretación, alcance, cumplimiento,
          o resolución, se resolverá ante las Cámaras Contencioso Administrativas de la ciudad de
          Córdoba.
        </p>
      </div>
      <Footer />
    </div>
  );
};
