import React, { useEffect, useState } from 'react';
import classes from './EnviarCodigo.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import segundologincodigo from '../../../assets/images/segundologincodigo.svg';
import Loading from '../../UI/Loading/Loading';
import { toast, ToastContainer } from 'react-toastify';
import { useAppState } from '../../../context/AppStateContext';
import { ERROR_TYPES } from '../../../constants/errorTypes';
import { generarCodigo, validarCodigo } from '../../../services/segundoLoginServices';

const EnviarCodigo = (props) => {
    const { location } = props;
    const { state } = location;
    const { Token, Metodo, Cuil } = state || {};
    const history = useHistory();
    const [code, setCode] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(60);
    const { isSuccess, isError, setIsError, isLoading, setIsLoading } = useAppState();
    const [newToken, setNewToken] = useState(Token);
    const [reenvios, setReenvios] = useState(0);

    useEffect(() => {
        if (isError.message
            && isError.type === ERROR_TYPES.FAILURE) {
            notify(isError.message);
        }
    }, [isError])

    useEffect(() => {
        window.history.replaceState(null, '', '');
    }, []);

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // Mover al siguiente input automáticamente
            if (value !== "" && index < 5) {
                const nextInput = document.getElementById(`digit-${index + 1}`);
                if (nextInput) nextInput.focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData("Text");
        if (/^\d{6}$/.test(pastedData)) {
            const newCode = pastedData.split(""); // Divide el código en un array de caracteres
            setCode(newCode);

            newCode.forEach((digit, index) => {
                const input = document.getElementById(`digit-${index}`);
                if (input) input.value = digit;
            });

            const lastInput = document.getElementById(`digit-5`);
            if (lastInput) lastInput.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && code[index] === "") {
            const prevInput = document.getElementById(`digit-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    const isCodeComplete = code.every((digit) => digit !== "");

    const handleReenviarCodigo = () => {
        if (reenvios >= 2) {
            toast.dismiss();
            notify('Has alcanzado el límite de reenvíos. Intenta otro método de autenticación.');
            return;
        }
        setIsLoading(true);
        const GenerarCodigo = async () => {
            try {
                setTimer(60);
                const data = await generarCodigo(
                    Cuil || isSuccess.value,
                    Metodo, //0: email, 1: sms
                );
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                setReenvios((prev) => prev + 1);
                setNewToken(data.return.token);
                setIsError(prev => ({
                    value: false,
                    type: '',
                    message: '',
                }));
            } catch (error) {
                console.log(error);
                setIsError(prev => ({
                    value: true,
                    type: ERROR_TYPES.FAILURE,
                    message: "Error procesando la solicitud",
                }));
            } finally {
                setIsLoading(false);
            }
        };
        GenerarCodigo();
    };

    const notify = (message) => {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "dark",
        });
    };

    const handleConfirmar = async (e) => {
        e.preventDefault(e);
        if (isCodeComplete) {
            const codeAsString = code.join("");

            setIsLoading(true);
            const ValidarCodigo = async () => {
                try {
                    const data = await validarCodigo(
                        codeAsString,
                        newToken,
                    );
                    if (!data.ok) {
                        throw new Error(data.error || 'Error procesando la solicitud')
                    }

                    setIsError(prev => ({
                        value: false,
                        type: '',
                        message: '',
                    }));

                    const { token, refreshToken } = data.return;
                    document.cookie = `tokenVeDi=${token}; path=/`;
                    document.cookie = `refreshTokenVeDi=${refreshToken}; path=/`;
                    setIsLoading(false);
                    window.open(process.env.REACT_APP_URL_PORTAL, '_self');

                } catch (error) {
                    console.log(error);
                    setCode(new Array(6).fill(""));
                    for (let i = 0; i < 6; i++) {
                        const input = document.getElementById(`digit-${i}`);
                        if (input) input.value = "";
                    }
                    setIsError(prev => ({
                        value: true,
                        type: error.code === 404
                            ? ERROR_TYPES.FAILURE
                            : ERROR_TYPES.VALIDATION,
                        message: "El código es incorrecto, por favor ingrésalo nuevamente",
                    }));
                    setIsLoading(false);
                    // setIsError(prev => ({
                    //     value: true,
                    //     type: ERROR_TYPES.FAILURE,
                    //     message: "No se pudo Validar el Código de Acceso.",
                    // }));
                }
            };
            ValidarCodigo();
        };
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    if (isLoading) {
        return <Loading show={isLoading} />
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={classes.container}
                >
                    <div className={classes.containerVerificar}>
                        <div className={classes.card}>
                            <div className={classes.illustration}>
                                <img src={segundologincodigo} alt="Verificar código" />
                            </div>
                            <h4>Introduce el código temporal que te  <br />enviamos por
                                {
                                    !Metodo ?
                                        <span> correo electrónico</span>
                                        :
                                        <span> teléfono</span>
                                }
                            </h4>
                            {
                                !isCodeComplete
                                    ? isError.value === false
                                        ?
                                        (
                                            <>
                                                <div className={classes.codeInput}>
                                                    {code.map((digit, index) => (
                                                        <input
                                                            key={index}
                                                            id={`digit-${index}`}
                                                            type="number"
                                                            maxLength="1"
                                                            value={digit}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            onPaste={handlePaste}
                                                        />
                                                    ))}
                                                </div>

                                                <button
                                                    className={timer > 0 ? classes.disabledButton : classes.confirmButton}
                                                    onClick={handleReenviarCodigo}
                                                    disabled={timer > 0}
                                                >
                                                    {timer > 0 ? `Reenviar en ${formatTime(timer)}` : "Reenviar"}
                                                </button>
                                                <button
                                                    className={classes.cancelButton}
                                                    onClick={() => history.push('/')}
                                                >Cancelar</button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div className={classes.codeInput}>
                                                    {code.map((digit, index) => (
                                                        <input
                                                            key={index}
                                                            id={`digit-${index}`}
                                                            type="number"
                                                            maxLength="1"
                                                            value={digit}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            onPaste={handlePaste}
                                                            style={isError.type === ERROR_TYPES.VALIDATION ? { border: '1px solid #9c1733' } : { border: '1px solid #ccc' }}
                                                        />
                                                    ))}
                                                </div>

                                                <p className={classes.errorText} style={{ visibility: isError.type === ERROR_TYPES.VALIDATION ? 'unset' : 'hidden' }} >
                                                    {isError.type === ERROR_TYPES.VALIDATION && isError.message}
                                                </p>
                                                <button
                                                    onClick={handleConfirmar}
                                                    className={isCodeComplete ? classes.confirmButton : classes.disabledButton}
                                                    // className={classes.confirmButton}
                                                    disabled={!isCodeComplete}
                                                >
                                                    Confirmar código
                                                </button>
                                                <button
                                                    className={classes.cancelButton}
                                                    onClick={() => history.push('/')}
                                                >Cancelar</button>
                                            </>
                                        )
                                    :
                                    (
                                        <>
                                            <div className={classes.codeDisplay}>
                                                {code.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        id={`digit-${index}`}
                                                        type="number"
                                                        maxLength="1"
                                                        value={digit}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                                        onPaste={handlePaste}
                                                        style={isError.type === ERROR_TYPES.VALIDATION ? { border: '1px solid #9c1733' } : { border: '1px solid #ccc' }}
                                                    />
                                                    // <span key={index}>{digit}</span>
                                                ))}
                                            </div>

                                            <p className={classes.errorText} style={{ visibility: isError.type === ERROR_TYPES.VALIDATION ? 'unset' : 'hidden' }} >
                                                {isError.type === ERROR_TYPES.VALIDATION && isError.message}
                                            </p>
                                            <button
                                                onClick={handleConfirmar}
                                                className={isCodeComplete ? classes.confirmButton : classes.disabledButton}
                                                // className={classes.confirmButton}
                                                disabled={!isCodeComplete}
                                            >
                                                Confirmar código
                                            </button>
                                            <button
                                                className={classes.cancelButton}
                                                onClick={() => history.push('/')}
                                            >Cancelar</button>
                                        </>
                                    )
                            }
                            <div className={classes.footer} style={{ visibility: isCodeComplete ? 'hidden' : 'unset' }}>
                                <p>¿No te llega el código?</p>
                                <button
                                    disabled={timer > 0}
                                    onClick={() => {
                                        history.push('/segundo-login', { ...state, Metodo: !state.Metodo, Cuil: Cuil || isSuccess.value });
                                    }}
                                >Probar otro método de autenticación</button>
                            </div>

                        </div>
                    </div>
                    <ToastContainer limit={1} />
                </div >
            </div >
        </>
    );
}

export default EnviarCodigo