import React, { useEffect, useState } from 'react';
import classes from './VerificarCuil.module.css';
import segundologincuil from '../../../assets/images/segundologincuil.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useAppState } from '../../../context/AppStateContext';
import { ERROR_TYPES } from '../../../constants/errorTypes';
import { toast, ToastContainer } from 'react-toastify';
import { obtenerMedios } from '../../../services/segundoLoginServices';

const VerificarCuil = () => {
    const history = useHistory();
    const [cuil, setCuil] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const { setIsSuccess, isError, setIsError, isLoading, setIsLoading, } = useAppState();

    useEffect(() => {
        window.history.replaceState(null, '', '');
    }, []);

    // useEffect(() => {
    //     if (cuil.length > 10 && isError.message)
    //         notify(isError.message);
    // }, [isError])

    useEffect(() => {
        setIsLoading(true)
        const ObtenerMedios = async () => {
            try {
                const data = await obtenerMedios(cuil.replace(/-/g, ''));
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                setIsError(prev => ({
                    value: false,
                    type: '',
                    message: '',
                }));
            } catch (error) {
                console.log(error);
                setIsError(prev => ({
                    value: true,
                    type: ERROR_TYPES.FAILURE,
                    message: "El CUIL ingresado no se encuentra en la base de datos.",
                }));
            } finally {
                setIsLoading(false);
            }
        };
        if (validateCuil(cuil)) ObtenerMedios();

    }, [cuil])

    const applyCuilMask = (value) => {
        const digits = value.replace(/\D/g, '');
        const formatted =
            digits.length <= 2
                ? digits
                : digits.length <= 10
                    ? `${digits.slice(0, 2)}-${digits.slice(2)}`
                    : `${digits.slice(0, 2)}-${digits.slice(2, 10)}-${digits.slice(10)}`;
        return formatted;
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const formatted = applyCuilMask(value);
        setCuil(formatted);

        const rawCuil = formatted.replace(/-/g, '');
        setIsValid(/^\d{11}$/.test(rawCuil));
    };

    const notify = (message) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "dark",
        });
    };

    const handleSubmit = () => {
        const rawCuil = cuil.replace(/-/g, '');
        setIsSuccess({ key: 'cuil', value: rawCuil });
        try {
            history.push('/segundo-login', { Cuil: rawCuil });
        } catch (err) {
            console.log(err);
        }
    };

    const handleBlur = (e) => {
        const value = e.target.value;
        if (value) validateCuil(value);
        setInputFocus(false);
    };

    const validateCuil = (cuil) => {
        const cuilRegex = /^\d{2}-\d{8}-\d{1}$/;
        if (!cuilRegex.test(applyCuilMask(cuil))) {
            // setCuilError('El CUIL ingresado es inválido');
            setIsError(prev => ({
                value: true,
                type: ERROR_TYPES.VALIDATION,
                message: "El CUIL ingresado es incorrecto.",
            }));
            return false;
        }
        setIsError(prev => ({
            value: false,
            type: '',
            message: '',
        }));
        return true;
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>

            <div className={classes.form}>
                <div className={classes.container} >
                    <div className={classes.containerImg}>
                        <img src={segundologincuil} alt="reCAPTCHA" />
                    </div>
                    <h2 className={classes.title}>Parece que CiDi no está disponible</h2>
                    <p className={classes.subtitle}>
                        Por favor ingresa manualmente tu CUIL
                        <br />                        <br />
                        para poder ingresar a <strong>VeDi</strong>
                    </p>

                    <div className={classes.inputContainer}>
                        <input
                            type="text"
                            inputMode="numeric"
                            name="cuil"
                            id="cuil"
                            required
                            value={cuil}
                            onChange={handleChange}
                            onFocus={() => setInputFocus(true)}
                            // onBlur={handleBlur}
                            placeholder="Ej.: 20-99999999-4 "
                            className={classes.input}
                            maxLength={13}
                            data-error={isError.value && cuil.length > 4}
                        />
                        <label className={classes.labelCuil} htmlFor="cuil">
                            {inputFocus || cuil ? 'CUIL' : 'Ingrese su CUIL'}
                        </label>
                    </div>
                    <p className={classes.errorText} style={{ display: isError.value && cuil.length > 4 ? 'block' : 'none', }}>
                        {isError.message}
                    </p>
                    <button
                        onClick={handleSubmit}
                        disabled={!isValid || isError.value || isLoading}
                        className={!isValid || isError.value || isLoading ? classes.buttonDisabled : classes.buttonEnabled}
                    >
                        Continuar
                    </button>
                    <button
                        className={classes.cancelButton}
                        onClick={() => history.push('/')}
                    >Cancelar</button>
                </div>
            </div>
            <ToastContainer limit={1} />

        </div>
    );
};

export default VerificarCuil