import React, { useCallback, useEffect, useState } from 'react';

const useReCaptchaV3 = (siteKey) => {
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  useEffect(() => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() => {
        // console.log("reCAPTCHA API cargada correctamente y lista.");
        setIsRecaptchaReady(prev => true);
      });
    } else {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
          window.grecaptcha.enterprise.ready(() => {
            // console.log("reCAPTCHA API cargada correctamente y lista.");
            setIsRecaptchaReady(prev => true);
          });
        }
      };

      script.onerror = () => {
        console.error("Error al cargar el script de reCAPTCHA.");
        setIsRecaptchaReady(prev => false);
      };
    }

    return () => {
      const existingScript = document.querySelector(`script[src*="${siteKey}"]`);
      if (existingScript) {
        existingScript.remove();
      }
    }
  }, [siteKey])

  const executeRecaptcha = useCallback(
    async (action) => {
      if (!isRecaptchaReady) {
        console.error("reCAPTCHA aún no está inicializado completamente.");
        return;
      }

      try {
        if (!window.grecaptcha || !window.grecaptcha.enterprise) {
          console.error("reCAPTCHA no está listo para ejecutarse.");
          return null;
        }

        const token = await window.grecaptcha.enterprise.execute(siteKey, { action });
        if (!token) {
          throw new Error("No se generó un token válido.");
        }
        return token;
      } catch (error) {
        console.error("Error al ejecutar reCAPTCHA:", error.message);
        return null;
      }
    },
    [isRecaptchaReady, siteKey]
  );

  return { isRecaptchaReady, executeRecaptcha };
}

export default useReCaptchaV3; 