import React, { createContext, useContext, useState } from "react";

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [isSuccess, setIsSuccess] = useState({ key: "", value: "" });
  const [isError, setIsError] = useState({
    value: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    isSuccess,
    setIsSuccess,
    isError,
    setIsError,
    isLoading,
    setIsLoading,
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);