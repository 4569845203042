import vediApi from "../utils/vedi-api/vediAPI";

export const obtenerMedios = async (Cuil) => {
    const response = await vediApi.post('/v2/Usuario/ObtenerMediosDeContacto', { Cuil });
    return response.data;
};

export const generarCodigo = async (Cuil, MetodoEnvio) => {
    const response = await vediApi.post('/v2/Usuario/GenerarCodigoDeAcceso', {
        Cuil,
        MetodoEnvio,
    });
    return response.data;
};

export const validarCodigo = async (Codigo, Token) => {
    const response = await vediApi.post('/v2/Usuario/ValidarCodigoDeAcceso', {
        Codigo,
        Token
    });
    return response.data;
};
export const cidiActivo = async () => {
    const response = await vediApi.get('/v2/Usuario/CidiActivo');
    return response.data;
};